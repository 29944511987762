import bg from './bg.png';
import './App.css';

function App() {
  return (
    <div className="App" style={{
      justifyContent: 'center',
      display: 'flex',
      backgroundColor: 'black !important',
      background: 'black',
      height: '100vh',
    }}>
        <img style={{
          position: 'absolute',
          height: '100%',
          justifyContent: 'center',
        }} src={bg} alt="logo" />
    </div>
  );
}

export default App;
